import "./Introduction.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import avatar from "../img/avatar.jpg";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ham_menu from "../img/ham-menu.svg";
import ham_menu_close from "../img/ham-menu-close.svg";
import { useState } from "react";
library.add(faTimes);

const Nav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header w-100 position-fixed bg-white">
      <div className="header__content align-items-center justify-content-between">
        <a
          href="/"
          className="header__logo-container d-flex align-items-center text-decoration-none"
        >
          <div className="header__logo-img-cont position-relative">
            <img
              src={avatar}
              alt="Tran Truong Gia Phat'Image "
              className="header__logo_img position-absolute w-100 h-auto"
            />
          </div>
          <span className="header__logo-sub text-uppercase fw-bold">
            Gia Phat
          </span>
        </a>
        <div className="header__main">
          <ul className="header__links">
            <li className={`header__link-wrapper`}>
              <NavLink activeClassName="active" exact to="/">
                HOME
              </NavLink>
            </li>
            <li className={`header__link-wrapper`}>
              <NavLink activeClassName="active" to="/about">
                ABOUT
              </NavLink>
            </li>
            <li className={`header__link-wrapper`}>
              <NavLink activeClassName="active" to="/project">
                PROJECTS
              </NavLink>
            </li>
            <li className={`header__link-wrapper`}>
              <NavLink activeClassName="active" to="/contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
          <div className="header__main-menu-ham-cont" onClick={toggleMenu}>
            {isMenuOpen ? (
              <img src={ham_menu_close} alt="close menu" />
            ) : (
              <img src={ham_menu} alt="open menu" />
            )}
          </div>
        </div>
      </div>
      <div className={`header__sm-menu ${isMenuOpen ? "sm-menu-open" : ""}`}>
        <div className="header__sm-menu-content">
          <ul className="header__sm-menu-links">
            <li className="header__sm-menu-links">
              <NavLink activeClassName="active" exact to="/">
                HOME
              </NavLink>
            </li>
            <li className="header__sm-menu-links">
              <NavLink activeClassName="active" to="/about">
                ABOUT
              </NavLink>
            </li>
            <li className="header__sm-menu-links">
              <NavLink activeClassName="active" to="/project">
                PROJECTS
              </NavLink>
            </li>
            <li className="header__sm-menu-links">
              <NavLink activeClassName="active" to="/contact">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Nav;
