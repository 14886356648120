import "./About.scss";
import avatar from "../img/avatar.jpg";
import hobby from "../img/hobby.png";
const About = () => {
  return (
    <section className="about" id="about">
      <div className="main-container">
        <div className="heading">
          <span className="heading-sec__main">About me</span>
          <span className="heading-sec__sub">
            Here you will find more information about me, and my current skills
            in terms of technology.
          </span>
        </div>
        <div className="about__content">
          <div className="about__content-main">
            <h3 className="about__content-title">Get to know me!</h3>
            <div className="about__content-details">
              <p className="about__content-details-para">
                I'm a <strong>student</strong> at Ton Duc Thang University,
                majoring Computer Network and Data Communication which relates
                to Information Technology. Currently, I am a{" "}
                <strong>Web Developer Intern</strong> building the Front-end and
                Back-end of Website or Web Application that depends on the
                requirements.
              </p>
              <p className="about__content-details-para">
                You can checkout some of my work in <strong>Projects</strong>{" "}
                section. These projects are my personal projects which I did
                when I studied at school.
              </p>
              <p className="about__content-details-para">
                I'm open to <strong>Job</strong> opportunities where I can
                contribute, learn and grow. If you have a good opportunities
                that matches my skills and experience, do not hesitate to{" "}
                <strong>contact</strong> me. Thank you so much.
              </p>
            </div>
            <a href="/contact" className="btn">
              Contact
            </a>
          </div>
          <div className="about__content-skills">
            <h3 className="about__content-title">My Skills</h3>
            <div className="skills">
              <div className="skills__skill">HTML</div>
              <div className="skills__skill">CSS</div>
              <div className="skills__skill">Javascript</div>
              <div className="skills__skill">Jquery</div>
              <div className="skills__skill">PHP</div>
              <div className="skills__skill">Wordpress</div>
              <div className="skills__skill">Bootstrap</div>
              <div className="skills__skill">Java</div>
              <div className="skills__skill">Spring boot</div>
              <div className="skills__skill">C#</div>
              <div className="skills__skill">ASP .NET</div>
              <div className="skills__skill">Git</div>
              <div className="skills__skill">SQL Basics</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
