import "./Contact.scss";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
const Mail = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zqm9ya8",
        "template_w8mtb7r",
        form.current,
        "TT_Bf7PWyboMK-GKM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <div id="contact" className="contact1">
      <div className="heading">
        <span className="heading-sec__main">Contact</span>
        <span className="heading-sec__sub">
          Feel free to Contact me by sending the form below and I will get
          back to you as soon as possible.
        </span>
      </div>
      <div className="contact__form-container">
        <form action='#' className='contact__form' ref={form} onSubmit={sendEmail} method='post'><input type='hidden' name='form-name' value='form 1' />
          <div className="contact__form-field">
            <label className="contact__form-label" for="name">Name</label>
            <input required placeholder="Enter Your Name" type="text" className="contact__form-input" name="user_name"
              id="name" />
          </div>
          <div className="contact__form-field">
            <label className="contact__form-label" for="email">Email</label>
            <input required placeholder="Enter Your Email" type="email" className="contact__form-input" name="email"
              id="email" />
          </div>
          <div className="contact__form-field">
            <label className="contact__form-label" for="message">Message</label>
            <textarea required cols="30" rows="10" className="contact__form-input" placeholder="Enter Your Message"
              name="message" id="message"></textarea>
          </div>
          <button type="submit" className="btn btn--theme contact__btn">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Mail;
