import "./Introduction.scss";
import "./Contact.scss";
import linkedlin from "../img/linkedin.png";
import gmail from "../img/gmail.png";
import location from "../img/location.png";
import facebook from "../img/facebook.png";
import { Link } from "react-router-dom";
import Mail from "../view/Mail";
import contact from "../img/contact.jpg";
const Contact = () => {
  const email = "example@example.com";
  const handleMailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <section className="contact">
      <div className="container-fluid contact-container">
        <div className="container info-container">
          <div className="row gx-5 pt-4">
            <div className="col-12">
              <Mail />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
